// extracted by mini-css-extract-plugin
export var accordionBlock = "martech-software-module--accordionBlock--4cb5a";
export var accordionWrapper = "martech-software-module--accordionWrapper--12c08";
export var billetShadow = "martech-software-module--billet-shadow--7b54f";
export var colorScheme__background__grayPale = "martech-software-module--colorScheme__background__gray-pale--89121";
export var headline = "martech-software-module--headline--3dd23";
export var headline_grid = "martech-software-module--headline_grid--3b2ee";
export var icomoon = "martech-software-module--icomoon--8dde6";
export var lowercase = "martech-software-module--lowercase--c6229";
export var marTechBlock = "martech-software-module--marTechBlock--c41a1";
export var positionedText = "martech-software-module--positionedText--fadb1";
export var solutions = "martech-software-module--solutions--e07a7";
export var transition = "martech-software-module--transition--6dc6d";
export var transitionBackground = "martech-software-module--transition-background--3ab86";
export var transitionReverse = "martech-software-module--transition-reverse--55acb";
export var uppercase = "martech-software-module--uppercase--b8a36";