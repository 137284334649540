import React from "react"
import { IMG } from "../const"

export const TITLES = {
  integrations: {
    title: (
      <>
        List of <strong>MarTech integrations</strong> we can implement
      </>
    ),
    strongText: (
      <>
        We have extensive experience in Integrations with CRM, Email Marketing
        Solutions, SMM &&nbsp;Content and Data and Web Analysis tools
      </>
    ),
    uppercase: "integrations",
    withIndent: true,
  },

  testimonials: {
    title: "clients’ testimonials",
    strongText: (
      <>
        our clients often come to us for recommendations&nbsp;— this is a good
        indicator of our services
      </>
    ),
    uppercase: (
      <>
        Trusted
        <br /> partnership
      </>
    ),
    withIndent: false,
  },

  range_services: {
    title: (
      <>
        <strong>marketing</strong> technology solutions
      </>
    ),
    strongText:
      "We offer a wide range of digital services for MarTech industries",
    uppercase: <>provide answers</>,
    withIndent: false,
  },
  advantages: {
    title: (
      <>
        LaSoft <strong>one‑stop shop</strong> technology partner:
      </>
    ),
    strongText:
      "We use a structured and tested approach to build a professional, long-term dedicated team for our partners",
    uppercase: (
      <>
        structured <br />
        &&nbsp;tested
      </>
    ),
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in <br /> MarTech software <br /> development
      </>
    ),
    strongText:
      "request a consultation so we could help build your web product",
    uppercase: "Project Estimate ",
    link: "#martech-software-contact-form",
    linkClassName: "marketing-estimate",
    imageDesktop: `${IMG.MARTECH_SOFTWARE}/scheme-white.svg`,
    imageDesktopWidth: 940,
    imageDesktopHeight: 92,
    industry: "martech",
    withIndent: false,
  },
}

export const SERVICES = {
  text: "",
  items: [
    {
      id: 1,
      title: "Software for marketing",
      text: (
        <>
          <p>
            When you partner with a&nbsp;MarTech software development company,
            you get unique products that solve your specific goal. Any web,
            mobile, and desktop:
          </p>
          <ul>
            <li>Custom MarTech software;</li>
            <li>
              Adapting low‑code software solutions for startups in the marketing
              industry field;
            </li>
            <li>
              Medium and corporate companies marketing data visualization
              products.
            </li>
          </ul>
        </>
      ),
      link: "",
    },
    {
      id: 2,
      title: "Product design",
      text: (
        <>
          <p>
            Users expect the Marketing product will be intuitive, user-friendly,
            and include only the necessary features. Achieve this goal with the
            help of design services:
          </p>
          <ul>
            <li>Product discovery;</li>
            <li>Prototyping;</li>
            <li>UX/UI design;</li>
            <li>Advanced data visualization.</li>
          </ul>
        </>
      ),
      link: "",
    },
    {
      id: 3,
      title: "MarTech Consulting",
      text: (
        <>
          <p>
            Creating cutting‑edge solutions for our clients and partners for
            almost 10&nbsp;years, we have gained significant marketing industry
            technological expertise and are eager to share it. In addition,
            there are consulting services we provide:
          </p>
          <ul>
            <li>
              Digital business design. Learn how to upgrade your business with
              marketing software.
            </li>
            <li>
              Product strategy development. Learn unique, innovative solutions
              for efficiently fulfilling the most critical business targets and
              user’ needs.
            </li>
            <li>
              Launch Adviser. Find out how much your marketing software will
              cost, how long it will take to develop it, and what technologies
              to use.
            </li>
          </ul>
        </>
      ),
      link: "",
    },
  ],
}

export const ADVANTAGES = [
  {
    id: 1,
    title: "Full circle software development",
    text:
      "We can plan, design, and develop your product as a solo vendor, and we do it. We take care of all aspects of software development, including product design and architecture, UI/UX design, Web App development, Quality Assurance, project management, and project support.",
  },
  {
    id: 2,
    title: "World-class Product Design expertise",
    text:
      "To create a new design or buy a template? Many of our customers have this dilemma when they decide to build a webpage. This option is suitable for testing your ideas.",
  },
  {
    id: 3,
    title: "Cloud Infrastructure deployment and support",
    text:
      "We can create or develop cloud infrastructure for business. Want to remove your business from the cloud? We’ll take care of it. Want to optimize your finances in DevOps? We will find the cheapest solution without losing the quality of your business!",
  },
  {
    id: 4,
    title: "Expertise in data analytics and custom dashboard development",
    text:
      "We have extensive knowledge in developing high-load dashboards with modern infographics. Our teammates worked in world-famous companies like HP, Pearson, Adidas, Boss, Calvin Klein, and s.Oliver.",
  },
  {
    id: 5,
    title: "Mobile development",
    text:
      "Android or iOS? You don’t have to choose. We make solutions for all operating systems.",
  },
]

export const INTEGRATIONS = [
  {
    name: "CRM",
    items: [
      { name: "Salesforce Sales Cloud", icon: "salesforce.svg" },
      { name: "HubSpot CRM", icon: "hubspot.svg" },
      { name: "Zoho CRM", icon: "zoho-crm.svg" },
      { name: "Dynamics 365", icon: "dynamics365.svg" },
      { name: "Pipedrive", icon: "pipedrive.svg" },
      { name: "Freshdesk", icon: "freshdesk.svg" },
      { name: "Zendesk Suite", icon: "zendesk.svg" },
      { name: "Less Annoying CRM", icon: "less-annoying-crm.svg" },
    ],
  },
  {
    name: "Email Marketing",
    items: [
      { name: "Gmail", icon: "gmail.svg" },
      { name: "Mailchimp", icon: "mailchimp.svg" },
      { name: "HubSpot Marketing Hub", icon: "hubspot.svg" },
      { name: "Active Campaign", icon: "activecampaign.svg" },
      { name: "MailerLite", icon: "mailerlite.svg" },
      { name: "GMass", icon: "gmass.svg" },
      { name: "Sendinblue", icon: "sendinblue.svg" },
    ],
  },
  {
    name: "SMM & Content",
    items: [
      { name: "Jasper", icon: "jasper-ai.svg" },
      { name: "Writesonic", icon: "writesonic.svg" },
      { name: "Semrush", icon: "semrush.svg" },
      { name: "Hootsuite", icon: "hootsuite.svg" },
      { name: "Airtable", icon: "airtable.svg" },
      { name: "Buffer", icon: "buffer.svg" },
      { name: "Content Studio", icon: "contentstudio.svg" },
      { name: "Instagram", icon: "instagram.svg" },
      { name: "Zoho Social", icon: "zoho-social.svg" },
      { name: "Meta for Business", icon: "meta.svg" },
      { name: "Twitter", icon: "twitter.svg" },
      { name: "Snapchat", icon: "snapchat.svg" },
      { name: "Sprout Social", icon: "sprout-social.svg" },
      { name: "Microsoft Advertising", icon: "microsoft.svg" },
      { name: "LinkedIn Ads", icon: "linkedin.svg" },
    ],
  },
  {
    name: "Data Analysis",
    items: [
      { name: "Google Cloud", icon: "google-cloud.svg" },
      { name: "Tableau", icon: "tableau.svg" },
      { name: "Microsoft Power BI", icon: "microsoft-power-bi.svg" },
      { name: "XLSTAT", icon: "xlstat.svg" },
      { name: "Sisense", icon: "sisense.svg" },
      { name: "Grow", icon: "grow.svg" },
      { name: "Splunk Enterprise", icon: "splunk.svg" },
      { name: "Zoho Analytics", icon: "zoho-analytics.svg" },
      { name: "Phocas Software", icon: "phocas.svg" },
      { name: "YouTube Analytics", icon: "youtube.svg" },
    ],
  },
  {
    name: "Web Analysis",
    items: [
      { name: "Google Analytics 360", icon: "google-analytics.svg" },
      { name: "Marketing 360", icon: "marketing360.svg" },
      { name: "Ahrefs", icon: "ahrefs.svg" },
      { name: "Hotjar", icon: "hotjar.svg" },
      { name: "Visitor Analytics", icon: "visitor-analytics.svg" },
      { name: "AccuRanker", icon: "accuranker.svg" },
      { name: "SE Ranking", icon: "seranking.svg" },
      { name: "ClickCease", icon: "clickcease.svg" },
      { name: "Lucky Orange", icon: "luckyorange.svg" },
      { name: "Adobe Analytics", icon: "adobe-analytics.svg" },
    ],
  },
]

export const TESTIMONIALS = [
  {
    id: "Casadesk",
    name: "Job Breur",
    position: "Chief Starting Officer",
    text:
      "LaSoft provided me with a very professional team that delivered me good results during a first project. Because of this good experience, I recently did not hesitate to select LaSoft for a new project. They provide all necessary experience, including UX/UI, programming, testing, project management, and cloud deployment. I enjoy working with guys and trust we've started a long-term relationship.",
    image: "Job-Breur",
  },
  {
    id: "KAMSA",
    name: "Matt Harbes",
    position: "CTO of KAMSA",
    text:
      "We've worked with LaSoft for several years and are continually impressed. Their passion and dedication to Kamsa is second to none, and they are always looking for new ways to improve and ensure the success of our team. We couldn't ask for a better partnership.",
    image: "Matt-Harbes",
  },
  {
    id: "Thinking Machine",
    name: "Jason Docter",
    position: "Owner of Thinking Machine",
    text:
      "LaSoft has an amazing team of highly skilled web designers and programmers. They were able to execute the ideas we had in our head and build a simple website with ease of functionality. They are always available for feedback or quick adjustments. Highly recommended.",
    image: "Jason-Docter",
  },
]
